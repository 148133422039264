import React, { ReactNode } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import texts from "../../texts/texts";

export interface IHeaderProps {
  logoSrc?: string;
  flowRestartLocation?: string;
  headerRight?: ReactNode;
  backDisabled?: boolean
}

const Header: React.FC<IHeaderProps> = (props) => {
  const { flowRestartLocation, logoSrc, headerRight, backDisabled } = props;
  const navigate = useNavigate();
  const params = useParams();
  const _goBack = () => {
    navigate(-1);
  };

  const _restartFlow = () => {
    if (flowRestartLocation) {
      if (params.returnId)
        navigate('/' + params.claimId + '/' + params.returnId)
      else
        navigate('/' + params.claimId)
    }
  };

  // TODO this needs some design adjustment for sizes closer to 768px. The instruction text might overlap with button
  return (
    <div data-testid={'header-wrapper'}
      className={'w-full h-12 desktops:h-40 flex items-center justify-between mt-4 desktops:m-0 desktop:mb-8  relative text-darkGrey desktops:text-lg z-40'}>
      <button
        className={`hidden items-center display-none justify-center absolute left-5 desktops:left-32 desktops:top-2/3 ${backDisabled ? 'opacity-50' : ''} `}
        disabled={backDisabled}
        onClick={_goBack}>
        <img className={'mr-3'}
          alt={'chevron-left'}
          src={'/icons/chevron-left.svg'} />
        {texts.back}
      </button>
      <img
        className={'max-w-[100px] max-h-[50px] absolute left-1/2 -translate-x-1/2 desktops:top-8 desktops:translate-x-0 desktops:left-32'}
        id="logo-img"
        alt={'logo'}
        src={logoSrc || '/logo.svg'} />
      {flowRestartLocation && (
        <button className={'text-primary absolute right-5 desktops:right-32 desktops:top-2/3'}
          onClick={_restartFlow}>{texts.restart}</button>
      )}
      {!flowRestartLocation && headerRight}
    </div>
  );
};

export default Header;
