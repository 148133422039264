import React, { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import RoomSelectPage from "../pages/RoomSelect/RoomSelectPage";
import ConfirmPhotosPage from "../pages/ConfirmPhotosPage/ConfirmPhotosPage";
import RoomCapturePage from '../pages/RoomCapturePage/RoomCapturePage';
// import TipsPage from "../pages/TipsPage/TipsPage";
import VideoTipsPage from '../pages/VideoTipsPage/VideoTipsPage';
import CameraTipsPage from "../pages/CameraTipsPage/CameraTipsPage";
import InstructionStepsPage from "../pages/InstructionStepsPage/InstructionStepsPage";
import RoomSubmittedPage from '../pages/RoomSubmittedPage/RoomSubmittedPage';

const CapturePage = () => {
       const { pathname } = useLocation();

       useEffect(() => {
              window.scrollTo(0, 0);
       }, [pathname]);


       return <Outlet />;
};

const RoomCaptureReturnRouter: React.FunctionComponent = () => {
       return (
              <Routes data-testid={'room-capture-router'}>
                     <Route path={':claimId/:returnId'}
                            element={<CapturePage />} >
                            <Route path=''
                                   element={<InstructionStepsPage />} />
                            <Route path={'room-select'}
                                   element={<RoomSelectPage />} />
                            <Route path={':roomId'}
                                   element={<CapturePage />}>
                                   <Route path={'tips'}
                                          element={<VideoTipsPage />} />
                                   <Route path={'camera-tips'}
                                          element={<CameraTipsPage />} />
                                   <Route path={'capture-room'}
                                          element={<RoomCapturePage />} />
                                   <Route path={'confirm-photos'}
                                          element={<ConfirmPhotosPage />} />
                                   <Route path={'room-submitted'}
                                          element={<RoomSubmittedPage />} />
                            </Route>
                     </Route>

              </Routes>
       );
};



export default RoomCaptureReturnRouter;
