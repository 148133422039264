import React from 'react';
import CaptureImageElement from "./CaptureImageElement/CaptureImageElement";

interface Props {
  elements: Array<{ placeholderImageSrc: string, title: string, content: string, id: number }>;
  onElementClick: (index: number) => () => void;
  onDeleteButtonClick?: (index: number) => () => void;
}

const CaptureImagesCarousel: React.FC<Props> = (props) => {
  const {elements, onElementClick, onDeleteButtonClick} = props;
  return (
    <div data-testid={'capture-images-carousel'}
         className="flex flex-row w-full  mt-8 overflow-x-scroll desktops:flex-wrap">
      {elements.map((element, index) => (
        <CaptureImageElement
          key={index}
          elementIndex={index}
          imageSrc={element.content}
          placeholderImageSrc={element.placeholderImageSrc}
          buttonOnClick={onElementClick(index)}
          deleteButtonOnClick={onDeleteButtonClick ? onDeleteButtonClick(index) : () => null}
          title={element.title}/>
      ))}
    </div>

  );
};

export default CaptureImagesCarousel;
