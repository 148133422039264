import React from 'react';

export interface IOutlineSelectionButton {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  checked?: boolean;
  className?: string;
  icon: string;
  text: string;
}

const OutlineSelectionButton: React.FC<IOutlineSelectionButton> = (props) => {
  const {onClick, icon, text, className, checked} = props;
  return (
    <button
      onClick={onClick}
      className={`select-none relative ${checked ? 'bg-lightBlueBackground' : 'active:bg-lightBlueBackground'} hover:bg-lightBlueBackground hover:border-primary border border-lightGreyOutline flex flex-col justify-center items-center rounded-2xl w-full min-w-[90px] max-w-[282px]  ${className}`}>
      <div className={'absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center'}>
        <div className="h-8 flex justify-center items-center mb-2">
          <img
            className="min-h-[20px]"
            alt={'icon'}
            src={icon}/>
        </div>
        {text}
        {checked ? <img
          data-testid="checked-icon"
          className="absolute top-4 right-4"
          src="/icons/checked.svg"
          alt={'checked'}/> : null}
      </div>
    </button>
  );
};

export default OutlineSelectionButton;
