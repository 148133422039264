import React, { PropsWithChildren } from 'react';
import Header from "../../components/Header/Header";
import BaseButton from "../../components/BaseButton/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import StepCard from '../../components/StepCard/StepCard';
import InstructionTitle from '../../components/InstructionTitle/InstructionTitle';
import Footer from '../../components/Footer/Footer';
import texts from "../../texts/texts";

const InstructionStepsPage: React.FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const params = useParams()

  if (params.returnId === 'room-select')
    return <></>

  const steps = [
    { num: 1, text: texts.instructionSteps1Text, img: '/images/steps/step-1.svg' },
    { num: 2, text: texts.instructionSteps2Text, img: '/images/steps/step-2.svg' },
    { num: 3, text: texts.instructionSteps3Text, img: '/images/steps/step-3.svg' }
  ];

  const navigateToRoomSelect = () => {
    navigate('room-select');
  };

  return (
    <>
      <Header />
      <div className={'w-full flex-1 flex flex-col items-center '}>
        <div className={'w-full desktops:w-desktop px-2 desktops:px-0 max-w-desktop flex flex-col items-center flex-1'}>
          <InstructionTitle
            text={texts.instructionStepsTitle}
          />
          <p
            className="w-full text-lg text-lightGrey hidden desktops:block desktops:text-center desktops:mb-8">{texts.instructionStepsClarification}</p>
          {steps.map((step) => (
            <StepCard key={step.num}
              stepNumber={step.num}
              stepText={step.text}
              imgSource={step.img} />
          ))}

        </div>
      </div>
      <Footer>
        <div className={'w-full px-2 desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex justify-center'}>
          <BaseButton
            label={texts.readyToStart}
            className={'w-full desktops:w-[70%] mt-14 mb-10 mx-auto'}
            onClick={navigateToRoomSelect}
          />
        </div>
      </Footer>
    </>
  );
};

export default InstructionStepsPage;

