import React from 'react';
import Header from "../../components/Header/Header";
import texts from "../../texts/texts";
import BaseButton from "../../components/BaseButton/BaseButton";
import Footer from "../../components/Footer/Footer";
import InstructionTitle from "../../components/InstructionTitle/InstructionTitle";
import { useNavigate, useParams } from "react-router-dom";

const RoomSubmittedPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const onAddAnotherRoomButtonClick = () => {
    if (params.returnId)
      navigate(`/${params.claimId}/${params.returnId}`);
    else
      navigate(`/${params.claimId}`);
  };

  const handleReturn = () => {
    window.location.href = process.env.REACT_APP_BASE_URL + '/#/app/allroom/' + params.returnId
  }

  return (
    <>
      <Header logoSrc={'/logo.svg'} />
      <div className={'w-full flex-1 flex flex-col items-center '}>
        <div
          className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex-1 text-center flex flex-col items-center'}>
          <InstructionTitle text={texts.roomSubmittedPageInstruction} />
          <img alt={'room-submitted'}
            src={'/images/all-done-illustration.svg'}
            className={'max-w-[280px] mt-16'} />
        </div>
        <div
          className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex flex-col justify-center items-center'}>
          {params.returnId && <BaseButton
            onClick={handleReturn}
            className={'w-full mt-6 mb-6 desktops:w-[70%]'}
            label={texts.finish} />}
          <BaseButton
            onClick={onAddAnotherRoomButtonClick}
            className={'w-full mb-10 desktops:w-[70%]'}
            label={texts.addAnotherRoom}
            outlined={true} />
        </div>
      </div>
      <Footer logoSrc={'/logo.svg'} />
    </>
  );
};

export default RoomSubmittedPage;
