import React, { useRef, useState } from 'react';
import { convertInputImageToBase64 } from "../../utils/utils";
import { dexieDb, IPicture } from "../../utils/indexedDb";
import { useLiveQuery } from "dexie-react-hooks";
import { useParams } from 'react-router-dom';

export interface IConfirmPhotosGrid {
  gridItems?: Array<{ wallNumber: number, imageSrc: string }>
}

const ConfirmPhotosGrid: React.FC<IConfirmPhotosGrid> = () => {
  const params = useParams()
  const pictures = useLiveQuery(async () => {
    const pics = await dexieDb.pictures.where('roomId').equals(params.roomId || '').toArray()
    return pics
  }, [params.roomId]) || [];
  const [currentElementIndex, setCurrentElementIndex] = useState<number>(0);
  const input = useRef<HTMLInputElement>(null);

  const onImageInputChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (event.target.files) {
      const base64Image = await convertInputImageToBase64(event.target.files[0]);
      await dexieDb.pictures.put({ content: base64Image, id: currentElementIndex, roomId: params.roomId });
    }
  };
  const onButtonClick = (elementIndex: number) => () => {
    setCurrentElementIndex(elementIndex);
    input.current?.click();
  };

  const renderGridItem = (wallNumber: number, imageSrc: string, arrIndex: number) => {
    if (imageSrc)
      return (
        <div data-testid={'confirm-photos-grid-item'}
          key={wallNumber}
          className={`w-full flex flex-col items-center justify-center mb-6 desktops:flex-1 ${arrIndex % 2 === 0 ? 'desktops:mr-6' : ''} text-lg`}>
          <img className={'max-w-full h-auto min-w-[270px] rounded-md'}
            alt={'test'}
            src={imageSrc} />
          <div className={'flex justify-between w-full mt-2'}>
            <span className={'text-lightGrey font-bold'}>Wall {wallNumber}</span>
            <button className={'text-primary'}
              onClick={onButtonClick(arrIndex)}>Retake photo
            </button>
          </div>
        </div>
      );
  };

  const renderGridItems = (gridItems: Array<IPicture>) => {
    return gridItems.map((item, index) => {
      return renderGridItem(item.id + 1, item.content, index);
    });
  };

  return (
    <>
      <div data-testid={'confirm-photos-grid'}
        className={'flex flex-col flex-wrap desktops:flex-row items-baseline justify-center text-lg'}>
        {renderGridItems(pictures)}
      </div>
      <input data-testid={'photo-uploader'}
        className={'hidden'}
        ref={input}
        capture={'environment'}
        type={'file'}
        onChange={onImageInputChange} />
    </>
  );
};

export default ConfirmPhotosGrid;
