import React from 'react';
import texts from "../../texts/texts";

export interface TipWithGoodBadExample {
  tipNumber: number;
  tipText?: string;
  tipTextsList?: Array<string>;
  goodImageSrc: string;
  badImageSrc: string;
}

const TipWithFullPhoto: React.FC<TipWithGoodBadExample> = (props) => {
  const {tipNumber, tipText, tipTextsList, goodImageSrc, badImageSrc} = props;
  return (
    <div className={'flex flex-col mb-11 desktops:text-center '}>
      <h1 className={'font-bold text-lg text-darkGrey'}>{texts.tip} {tipNumber}</h1>
      {tipText && (
        <p className={'text-lg mt-5 mb-8 text-lightGrey'}>{tipText}</p>
      )}
      {tipTextsList && (
        <ul className={'list-disc text-lightGrey pl-4 my-5 text-left'}>
          {tipTextsList.map((text, index) => <li key={`tip-1-text-${index}`}>{text}</li>)}
        </ul>

      )}
      <div className={'flex flex-col max-w-full desktops:flex-row'}>
        <div
          className={'flex w-full mb-6 desktops:flex-col desktops:items-center desktops:mb-0 desktops:mr-6 desktops:w-1/2'}>
          <div className={'flex flex-1 desktops:mb-6'}>
            <img data-testid={'tip-with-example-bad-photo'}
                 alt={`tip-${tipNumber}-bad`}
                 src={badImageSrc}/>
          </div>
          <img className={'ml-9 desktops:max-w-[60px] desktops:ml-0'}
               alt={'declined'}
               src={'/icons/declined.svg'}/>
        </div>
        <div className={'flex max-w-full desktops:flex-col desktops:items-center desktops:w-1/2'}>
          <div className={'flex flex-1 desktops:mb-6'}>
            <img data-testid={'tip-with-example-good-photo'}
                 alt={`tip-${tipNumber}-good`}
                 src={goodImageSrc}/>
          </div>
          <img className={'ml-9 desktops:max-w-[60px] desktops:ml-0'}
               alt={'accepted'}
               src={'/icons/accepted.svg'}/>
        </div>
      </div>
    </div>
  );
};

export default TipWithFullPhoto;
