import  Dexie, {Table} from "dexie";

export interface IPicture {
  id: number;
  content: string;
  roomId?: string;
  damagePictures?: Array<{ [key in string]?: string }>
}

export class MySubClassedDexie extends Dexie {
  pictures!: Table<IPicture>;

  constructor() {
    super('picture-database');
    this.version(5).stores({
      pictures: '++id, content, roomId, imageIndex, damagePictures',
    });
  }
}

export const dexieDb = new MySubClassedDexie();
