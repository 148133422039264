import React from 'react';
import texts from "../../texts/texts";

export interface IFooterProps {
  logoSrc?: string;
  hideOnDesktop?: boolean;
  children?: JSX.Element | JSX.Element[]
}

const Footer: React.FC<IFooterProps> = ({ logoSrc, hideOnDesktop, children }) => {


  return (
    <div data-testid={'footer-wrapper'} style={{ maxWidth: '768px' }}
      className={`z-10 bg-white fixed w-full flex items-center justify-center bottom-0 text-darkGrey ${hideOnDesktop ? 'desktops:hidden' : ''}`}>
      <div className='w-full flex flex-col items-center justify-center'>
        {children}
        <span className="text-xs text-lightGrey mt-1">{texts.poweredBy} &nbsp;
          <img id="logo-img"
            alt={'logo'} className='float-right'
            src={logoSrc || '/logo.svg'} />
        </span>
      </div>
    </div>
  );
};

export default Footer;
