import axios from './base'

export const addNewRoom = (claimId: string, roomType: string) => {
    return axios.post(`${claimId}`, { roomType });
}

const uploadImage = (url: string, data: any) => {
    return axios.post(url, data, {
        headers: {
            authorization: '',
            'content-type': 'multipart/form-data',
        }
    })
};

const makeFormData = (uploadFields: any, file: File) => {
    const data = new FormData();
    data.append('key', uploadFields.key);
    data.append('x-amz-algorithm', uploadFields['x-amz-algorithm']);
    data.append('x-amz-credential', uploadFields['x-amz-credential']);
    data.append('x-amz-date', uploadFields['x-amz-date']);
    data.append('x-amz-security-token', uploadFields['x-amz-security-token']);
    data.append('policy', uploadFields.policy);
    data.append('x-amz-signature', uploadFields['x-amz-signature']);
    data.append('x-amz-meta-claimId', uploadFields.claimId);
    data.append('x-amz-meta-roomId', uploadFields.roomId);
    data.append('x-amz-meta-submitter', uploadFields.submitter);
    data.append('x-amz-meta-imageField', uploadFields.imageField);
    if (uploadFields.imageNumber) {
        data.append('x-amz-meta-imageNumber', uploadFields.imageNumber);
    }
    data.append('file', file);
    return data;
};

export const getImageLink = (claimId: string, roomId: string, file: File, ext: string, imageNumber: string, imageField = "overviewImages") => {
    return new Promise((resolve, reject) => {
        axios.post(`${claimId}/${roomId}/image`, { ext, imageNumber, imageField }).then(resp => {
            const formData = makeFormData(resp.data.uploadFields, file)
            uploadImage(resp.data.uploadLink, formData).then(result => {
                resolve(result.data)
            })
        }).catch(err => { reject(err) })
    })
};

export const submitRoom = (claimId: string, roomId: string, roomType: string) => {
    return axios.post(`${claimId}/${roomId}/process`, { roomType })
}
