import React from 'react';

type Props = {
  className?: string,
  label?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  icon?: string,
  type?: "button" | "submit",
}

const AddButton: React.FC<Props> = (props) => {
  const {className, label, disabled, loading, onClick, icon, type} = props;

  return (
    <button
      type={type || 'button'}
      disabled={loading || disabled}
      className={`${className} ${disabled ? 'bg-primary opacity-50 cursor-not-allowed' : 'cursor-pointer'} bg-primary text-white hover:opacity-75 rounded-full w-56 h-11 font-bold`}
      onClick={loading || disabled ? undefined : onClick}>
      {loading ?
        <div className='loader'
             id="base-button-loader"/>
        :
        <div className="flex flex-row justify-center items-center">
          {icon ? <img className="pr-3 h-4"
                       alt={'icon'}
                       src={icon}/> : null}
          <span>{label}</span>
        </div>
      }
    </button>
  );
};

export default AddButton;
