import React from 'react';
import {StringLiteral} from 'typescript';
import OutlineSelectionButton from '../OutlineSelectionButton/OutlineSelectionButton';

export interface GridElement {
  text: string,
  icon: string,
  className?: string,
}

export interface ISelectionGrid {
  className?: StringLiteral;
  onClick: (element: string) => React.MouseEventHandler<HTMLButtonElement>;
  elements?: Array<GridElement>;
  checked?: string;
  instruction?: string;
}

const SelectionGrid: React.FC<ISelectionGrid> = (props) => {
  const {onClick, elements, checked} = props;
  return (
    <div className="w-full flex flex-row flex-wrap">
      {elements?.map((element, index) => (
        <OutlineSelectionButton
          data-testid={'grid-outline-selection-button'}
          key={index}
          onClick={onClick(element.text)}
          text={element.text}
          icon={element.icon}
          className={element.className}
          checked={checked === element.text}/>
      ))}
    </div>
  );
};

export default SelectionGrid;
