import React from 'react';

export interface IStepCard {
  stepNumber: number;
  stepText: string;
  imgSource?: string;
}

const StepCard: React.FC<IStepCard> = (props) => {
  const {stepNumber, stepText, imgSource} = props;
  return (
    <div
      className={'flex flex-row mb-4 p-4 min-h-[82px] w-full rounded-2xl justify-around items-center desktops:text-center bg-blueBackground select-none'}>
      <p data-testid={'step-number'}
         className={'w-2/12 text-base font-semibold pl-3 text-lightGrey'}>{stepNumber}</p>
      <div className="w-10/12 h-full flex flex-row items-center justify-between">
        <p data-testid={'step-text'}
           className={'w-full desktops:w-3/6  text-left text-xl text-lightGrey'}>{stepText}</p>
        <img className="w-2/6 hidden largePhone:block desktops:block"
             src={imgSource}
             alt=""/>
      </div>
    </div>
  );
};

export default StepCard;
