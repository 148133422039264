import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import CaptureImagesCarousel from '../../components/CaptureImagesCarousel/CaptureImagesCarousel';
import Header from "../../components/Header/Header";
import InstructionTitle from "../../components/InstructionTitle/InstructionTitle";
import SeeOurTips from "../../components/SeeOurTips/SeeOurTips";
import BaseButton from "../../components/BaseButton/BaseButton";
import { useNavigate, useParams } from "react-router-dom";
import texts from "../../texts/texts";
import { dexieDb, IPicture } from "../../utils/indexedDb";
import { convertInputImageToBase64 } from "../../utils/utils";
import { useLiveQuery } from "dexie-react-hooks";
import Footer from "../../components/Footer/Footer";
import WarningText from "../../components/WarningText/WarningText";
import Modal from '../../components/Modal/Modal';

const RoomCapturePage: React.FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [count, setCount] = useState(4);
  const [error, setError] = useState<string | null>(null);
  const [images, setImages] = useState<IPicture[]>([
    {
      id: 0,
      content: '',
      roomId: params.roomId
    },
    {
      id: 1,
      content: '',
      roomId: params.roomId
    },
    {
      id: 2,
      content: '',
      roomId: params.roomId
    },
    {
      id: 3,
      content: '',
      roomId: params.roomId
    }
  ]);

  const pictures = useLiveQuery(async () => {
    const pics = await dexieDb.pictures.where('roomId').equals(params.roomId || '').toArray()
    return pics
  }, [params.roomId]) || [];

  const imagesWithAdditionalData = images.map(image => ({
    ...image,
    placeholderImageSrc: `/icons/rooms/wall-${image.id + 1}.svg`,
    title: `${texts.wall} ${image.id + 1}`
  }));
  const [currentElementIndex, setCurrentElementIndex] = useState<number>(0);
  const input = useRef<HTMLInputElement>(null);
  const nextButtonDisabled = pictures.filter(image => image.content !== '').length < 4;


  useEffect(() => {
    if (pictures.length > 0) {
      let allImgs = [...images]
      if (pictures.length > images.length) {
        allImgs = pictures.map((x, idx) => { return { id: idx, content: '', roomId: params.roomId } })
      }
      const updatedImages = allImgs.map(image => {
        const imageFromDb = pictures.find(picture => picture.id === image.id);
        if (imageFromDb) {
          return imageFromDb;
        }
        return image;
      });
      setCount(updatedImages.length);
      setImages(updatedImages);
    }
    // eslint-disable-next-line 
  }, [pictures]);

  useEffect(() => {
    if (count <= 6 && images.findIndex((image) => image.content === '') === -1) {
      setImages([...images, { id: images.length, content: '', roomId: params.roomId }, { id: images.length + 1, content: '', roomId: params.roomId }]);
    }
    else if (count <= 7 && images.findIndex((image) => image.content === '') === -1) {
      setImages([...images, { id: images.length, content: '', roomId: params.roomId }]);
    }
  }, [images, params.roomId, count]);



  const onImageInputChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    const updatedImages = [...images];
    try {
      if (event.target.files) {
        const base64Image = await convertInputImageToBase64(event.target.files[0]);
        event.target.value = ''
        await dexieDb.pictures.put({ content: base64Image, id: currentElementIndex, roomId: params.roomId });
        updatedImages[currentElementIndex].content = base64Image;
        updatedImages[currentElementIndex].id = currentElementIndex;
        setImages(updatedImages);
      }
    }
    catch (ex) {
      setError(`Picture cannot be uploaded. [${JSON.stringify(ex)}]`)
    }
  };

  const onButtonClick = (elementIndex: number) => () => {
    setCurrentElementIndex(elementIndex);
    input.current?.click();
  };

  const onDeleteButtonClick = (elementIndex: number) => async () => {
    const updatedImages = [...images];
    await dexieDb.pictures.delete(elementIndex);
    updatedImages[elementIndex].content = '';
    setImages(updatedImages);
    if (input.current) {
      input.current.files = null;
    }
  };

  const navigateToConfirmPhotos = () => {
    if (params.returnId)
      navigate('/' + params.claimId + '/' + params.returnId + '/' + params.roomId + '/confirm-photos');
    else
      navigate('/' + params.claimId + '/' + params.roomId + '/confirm-photos');
  };


  return (
    <>
      <Header flowRestartLocation={'/'} />
      <div className={'w-full flex-1 flex flex-col items-center '}>
        <div className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex-1'}>
          <InstructionTitle
            text={texts.roomCapturePageInstruction}
            subtitleText={texts.roomCapturePageSecondaryInstruction}
          />
          {error && <Modal handleClose={() => setError(null)} title='Error' text={error} buttonText='Got it' />}
          <CaptureImagesCarousel onElementClick={onButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            elements={imagesWithAdditionalData} />
          <input accept={'image/*'}
            data-testid={'photo-uploader'}
            className={'hidden'}
            ref={input}
            capture={'environment'}
            type={'file'}
            onChange={onImageInputChange} />
          <SeeOurTips className={'desktops:text-center mt-5'} />
        </div>
        <div className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex flex-col desktops:items-center justify-center'}>
          <WarningText text={texts.mustAddAtLeast4Walls}
            hidden={!nextButtonDisabled}
            className={'mt-6'} />
        </div>
      </div>
      <Footer>
        <div className='flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop'>
          <BaseButton disabled={nextButtonDisabled}
            label={texts.next}
            className={`w-full desktops:w-[70%] mx-auto ${!nextButtonDisabled ? 'mt-6' : ''}`}
            onClick={navigateToConfirmPhotos} />
        </div>
      </Footer>

    </>
  );
};

export default RoomCapturePage;

