import React, { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface IModalProps {
  title: string;
  text: string;
  buttonText: string;
  handleClose(): void;
}

const Modal: React.FC<IModalProps> = (props) => {
  const { title, text, buttonText, handleClose } = props;
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    handleClose()
  }

  return (
    <Transition appear
      show={isOpen}
      as={Fragment}>
      <Dialog as="div"
        className="relative"
        onClose={closeModal}>


        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center text-white">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full bg-darkGrey  max-w-[400px] py-3 px-4 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all z-10">
                <Dialog.Title
                  as="h3"
                  className="text-base font-semibold"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-tooltipText">
                    {text}
                  </p>
                </div>

                <div className="mt-4 flex justify-center">
                  <button
                    type="button"
                    className="inline-flex font-semibold justify-center rounded-2xl bg-white text-primary px-14 py-3 focus:outline-none"
                    onClick={closeModal}
                  >
                    {buttonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
