import React from 'react';
import RoomCaptureRouter from "./routers/RoomCaptureRouter";
import 'react-day-picker/dist/style.css';
import RoomCaptureReturnRouter from './routers/RoomCaptureReturnRouter';

function App() {
  return (
    <div style={{ maxWidth: '768px', margin: '0 auto', height: '100%' }}>
      <RoomCaptureRouter />
      <RoomCaptureReturnRouter />
    </div>
  );
}

export default App;
