import React, { PropsWithChildren, useState, } from 'react';
import Header from "../../components/Header/Header";
import InstructionTitle from "../../components/InstructionTitle/InstructionTitle";
import ConfirmPhotosGrid from "../../components/ConfirmPhotosGrid/ConfirmPhotosGrid";
import BaseButton from "../../components/BaseButton/BaseButton";
import SeeOurTips from "../../components/SeeOurTips/SeeOurTips";
import texts from "../../texts/texts";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useLiveQuery } from "dexie-react-hooks";
import { dexieDb } from "../../utils/indexedDb";
import { getImageLink, submitRoom } from '../../REST/capture';
import { base64ToFile } from '../../utils/utils';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';

const ConfirmPhotosPage: React.FC<PropsWithChildren> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const pictures = useLiveQuery(async () => {
    const pics = await dexieDb.pictures.where('roomId').equals(params.roomId || '').toArray()
    return pics
  }, [params.roomId]) || [];

  const onNextButtonClick = () => {
    (async () => {
      setLoad(true)
      const failed: number[] = []
      for (let i = 0; i < pictures.length; i++) {
        const img = pictures[i]
        const getBase64StringFromDataURL = (dataURL: string) => dataURL.replace('data:', '').replace(/^.+,/, '');
        const file = base64ToFile(getBase64StringFromDataURL(img.content))
        await getImageLink(params.claimId || '', params.roomId || '', file, 'jpeg', (i + 1).toString()).then(res => {
          dexieDb.pictures.delete(img.id)
        }).catch(err => {
          failed.push(img.id)
        })
      }
      if (failed.length === 0) {
        await submitRoom(params.claimId || '', params.roomId || '', "Living")
        if (params.returnId)
          navigate(`/${params.claimId}/${params.returnId}/${params.roomId}/room-submitted`)
        else
          navigate(`/${params.claimId}/${params.roomId}/room-submitted`)
      }
      else {
        setError(`Unable to upload image(s) ${failed.join(',')}`)
        setLoad(false)
      }
    })()
  };

  return (
    <>
      <Header />
      <div className={'w-full flex-1 flex flex-col items-center pb-48'}>
        <div className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex-1'}>
          <InstructionTitle text={texts.confirmPhotosPageInstruction} />
          <p
            className={'mt-5 mb-8 text-lightGrey text-lg desktops:text-center'}>{texts.confirmPhotosPageSecondaryInstruction}</p>
          <img alt={'confirm'}
            className={'mb-6 mx-auto'}
            src={'/images/confirm-photo-illustration.svg'} />
          <ConfirmPhotosGrid />
          <SeeOurTips className={'mt-6 desktops:w-full desktops:text-center'} />
        </div>

        <Loading open={load} handleClose={() => setLoad(false)} />
        {error && <Modal handleClose={() => setError(null)} buttonText={'Got it'} title='Caution' text={error} />}

      </div>
      <Footer>
        <div className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex justify-center'}>
          <BaseButton onClick={onNextButtonClick}
            className={'w-full desktops:w-[70%]'}
            label={texts.looksGood} />
        </div>
      </Footer>
    </>
  );
};

export default ConfirmPhotosPage;

