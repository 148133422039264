import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import texts from "../../texts/texts";
import BaseButton from "../../components/BaseButton/BaseButton";
import Footer from "../../components/Footer/Footer";
import InstructionTitle from "../../components/InstructionTitle/InstructionTitle";

const CameraTipsPage = () => {
  const navigate = useNavigate();
  const params = useParams()
  const { roomId, claimId, returnId } = params
  const navigateToPhotographRoomPage = () => {
    if (params.returnId)
      navigate(`/${claimId}/${returnId}/${roomId}/capture-room`);
    else
      navigate(`/${claimId}/${roomId}/capture-room`);
  };


  return (
    <>
      <Header />
      <div className={'w-full flex-1 flex flex-col items-center '}>
        <div className={'desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex-1 desktops:text-center'}>
          <InstructionTitle text={texts.cameraTipsPageInstructionText}
            subtitleText={texts.cameraTipsPageInstructionSubtitle} />
          <div className={'flex justify-center'}>
            <video muted autoPlay loop playsInline>
              <source src={`${process.env.PUBLIC_URL}/videos/pinch.mp4`} />
            </video>
          </div>

        </div>
        <div className={'desktops:w-desktop px-4 desktops:px-0 max-w-desktop'}>
          <p className={'text-lg text-lightGrey mt-2 desktops:text-center'}>{texts.cameraTipsPageBottomText}</p>
        </div>
      </div>
      <Footer>
        <div className='flex flex-row w-full flex-1 max-w-desktop items-center desktops:w-desktop'>
          <BaseButton onClick={navigateToPhotographRoomPage}
            label={texts.next}
            className={'w-full'} />
        </div>
      </Footer>
    </>
  );
};

export default CameraTipsPage;
