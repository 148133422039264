import React from 'react';

interface IBaseButtonProps {
  className?: string,
  label?: string,
  disabled?: boolean,
  loading?: boolean,
  onClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined
  outlined?: boolean,
  icon?: string,
  type?: "button" | "submit",
}

const BaseButton: React.FC<IBaseButtonProps> = (props) => {
  const {className, label, disabled, loading, onClick, outlined, icon, type} = props;

  return (
    <button
      data-testid={'base-button'}
      type={type || 'button'}
      disabled={loading || disabled}
      className={`${className} ${disabled ? 'bg-primary opacity-50 cursor-not-allowed' : 'cursor-pointer'} ${outlined ? 'border-2 border-primary text-primary' : 'bg-primary text-white'} hover:opacity-75 rounded-lg w-80 h-12 font-bold`}
      onClick={loading || disabled ? undefined : onClick}>
      {loading ?
        <div className='loader'
             id="base-button-loader"/>
        :
        <div className="flex flex-row justify-center items-center">
          {icon ? <img className="pr-3 h-4"
                       alt={'icon'}
                       src={icon}/> : null}
          <span>{label}</span>
        </div>
      }
    </button>
  );
};

export default BaseButton;
