import React from 'react';

type Props = {
  className?: string,
  text: string;
  hidden?: boolean;
}

const WarningText: React.FC<Props> = (props) => {
  const {className, text, hidden} = props;

  return (
    <p data-testid={'warning-text'}
       className={`text-warning text-lg text-left ${className} ${hidden ? 'hidden' : ''}`}>{text}</p>
  );
};

export default WarningText;
