import React from 'react';
import { Link, useParams } from 'react-router-dom';
import texts from "../../texts/texts";

interface ISeeOurTipsProps {
  className?: string;
}

const SeeOurTips: React.FC<ISeeOurTipsProps> = (props) => {
  const { className } = props;
  const params = useParams()

  const getLink = () => {
    if (params.returnId) {
      return '/' + params.claimId + '/' + params.returnId + '/' + params.roomId + '/tips'
    } else
      return '/' + params.claimId + '/' + params.roomId + '/tips'
  }

  return (
    <p className={`text-lg text-lightGrey ${className}`}>
      {texts.gotQuestions}&nbsp;
      <Link className={'text-primary underline'}
        replace
        to={getLink()}>{texts.here}</Link>
    </p>
  );
};

export default SeeOurTips;
