import Backdrop from '@mui/material/Backdrop';


export default function Loading({ open, handleClose }: any) {

    return (

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            <img width={50} alt='Loading Icon' src={`${process.env.PUBLIC_URL}/Loading.gif`} />
        </Backdrop>

    );
}
