import React, { PropsWithChildren, useState } from 'react';
import Header from "../../components/Header/Header";
import SelectionGrid from '../../components/SelectionGrid/SelectionGrid';
import { useNavigate, useParams } from "react-router-dom";
import texts from "../../texts/texts";
import { useLocalStorageWithState } from "../../hooks/useLocalStorageWithState";
import Footer from "../../components/Footer/Footer";
import InstructionTitle from "../../components/InstructionTitle/InstructionTitle";
import { addNewRoom } from '../../REST/capture';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';

const RoomSelectPage: React.FC<PropsWithChildren> = () => {
  const navigate = useNavigate();
  //const location = useLocation();
  const params = useParams()
  const [load, setLoad] = useState(false)
  const [roomType, setRoomType] = useLocalStorageWithState('roomType', '');
  const [error, setError] = useState<string | null>(null)
  const rooms = [texts.bathroom, texts.bedroom, texts.kitchen, texts.basement, texts.living, texts.laundry, texts.sunroom, texts.dining, texts.other];



  const handleSelectRoom = (selectedRoom: string) => () => {
    setRoomType(selectedRoom);
    setLoad(true)
    addNewRoom(params.claimId || '', selectedRoom).then(resp => {
      if (resp.data) {
        const { roomId } = resp.data;
        if (params.returnId)
          navigate(`/${params.claimId}/${params.returnId}/${roomId}/tips`);
        else
          navigate(`/${params.claimId}/${roomId}/tips`);
      }
      setLoad(false)
    }).catch(ex => {
      try {
        if (ex.response.status >= 400 && ex.response.status < 500) {
          setError(ex.response.data)
        }
        else
          throw new Error('Error')
      }
      catch (err) {
        setError("Unable to Continue. Please contact Adminstrator")
      }
      setLoad(false)
    })
  };

  const createElementsWithData = () => {
    return rooms.map((room, index) => {
      return {
        text: room,
        icon: `/icons/rooms/${room}.svg`,
        className: `capitalize basis-[calc(33%_-_5px)] pt-[calc(33%_-_5px)] ${index > 2 ? 'mt-2' : ''} ${index % 3 === 0 ? 'mr-1' : index % 3 === 1 ? 'mx-1' : index % 3 === 2 ? 'ml-1' : ''}`
      };
    });
  };

  const handleClose = () => setLoad(false)

  return (
    <>
      <Header logoSrc={'/logo.svg'} />
      <div className={'w-full flex-1 flex flex-col items-center '}>
        <div className={'w-full desktops:w-desktop px-4 desktops:px-0 max-w-desktop flex-1'}>
          <InstructionTitle text={texts.roomSelectPageInstruction} />
          <SelectionGrid
            checked={roomType}
            instruction={texts.roomSelectPageInstruction}
            onClick={handleSelectRoom}
            elements={createElementsWithData()}
          />
        </div>
        {error && <Modal handleClose={() => setError(null)} title='Caution' text={error} buttonText='Got it' />}
        <Loading open={load} handleClose={handleClose} />
      </div>
      <Footer />
    </>
  );
};

export default RoomSelectPage;

