import Compressor from 'compressorjs';

export const compressImage = (file: File): Promise<File> => {
  return new Promise((resolve) => {
    if (file.size < 1000000) {
      resolve(file);
    } else {
      new Compressor(file, {
        maxHeight: 1024,
        maxWidth: 1024,
        quality: 0.9,
        success: (compressedResult) => {
          resolve(new File([compressedResult], file.name, { type: 'image/jpeg' }));
        }
      });
    }
  });
};

export const base64ToFile = (base64Image: string, contentType = 'image/jpeg') => {
  const sliceSize = 512;
  const byteCharacters = window.atob(base64Image);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return new File([new Blob(byteArrays, { type: contentType })], 'uploaded-image.jpg', { type: contentType });
};

export const convertBase64ImageToFileAndCompress = async (base64Image: string, contentType = 'image/jpeg') => {
  return await compressImage(base64ToFile(base64Image, contentType));
};


export const convertInputImageToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = async (fileReadEvent) => {
      return resolve(fileReadEvent.target?.result as string);
    };
    compressImage(file).then(newFile => {
      fileReader.readAsDataURL(newFile)
    }).catch(err => {
      console.log(err)
      fileReader.readAsDataURL(file)
    })

  });
};