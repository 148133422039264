import React from 'react';
import AddButton from "../../AddButon/AddButton";
import texts from "../../../texts/texts";

interface Props {
  title?: string;
  placeholderImageSrc?: string;
  imageSrc?: string;
  buttonOnClick?: () => void;
  deleteButtonOnClick?: () => void;
  className?: string;
  disabled?: boolean;
  elementIndex: number;
}

const CaptureImageElement: React.FC<Props> = (props) => {
  const {title, imageSrc, buttonOnClick, deleteButtonOnClick, placeholderImageSrc} = props;
  const imageRef = React.useRef<HTMLImageElement>(null);

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (buttonOnClick) {
      buttonOnClick();
    }
    imageRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  };

  const onDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (deleteButtonOnClick) {
      deleteButtonOnClick();
    }
  };
  return (
    <div key={title}
         className={`flex flex-col items-center justify-center desktops:flex-1 mr-6 desktops:mx-3 desktops:mb-6 text-lg relative`}>
      <span className={'text-lightGrey font-medium'}>{title}</span>
      <button
        onClick={onButtonClick}
        className={`min-h-[160px] min-w-[227px] w-full rounded-md border border-dashed border-primary ${imageSrc ? "border-none" : ''} flex justify-center items-center relative`}>
        <div className={'relative'}>
        <img data-testid={'image-captured'}
             ref={imageRef}
             className={'max-h-[160px] desktops:max-h-[200px]'}
             alt={'captured'}
             src={imageSrc || placeholderImageSrc}/>
        {imageSrc && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <span role={'button'}
                tabIndex={0}
                onClick={onDeleteButtonClick}
                className={'p-2 rounded-full absolute right-2 top-4 bg-primary z-10'}>
            <img alt={'close'}
                 src={'/icons/close.svg'}
            />
          </span>
        )}
        </div>

      </button>
      <div className={'flex justify-center w-full mt-3'}>
        {imageSrc ? (
          <button className={'text-primary w-full'}
                  onClick={buttonOnClick}>{texts.retakePhoto}</button>
        ) : (
          <AddButton icon={'/icons/add.svg'}
                     label={'Add photo'}
                     onClick={buttonOnClick}/>
        )}
      </div>
    </div>
  );
};

export default CaptureImageElement;
