import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper'
import 'swiper/css';
import "swiper/css/pagination";
import './VideoTipsPage.css';
import { Pagination } from "swiper";
import Header from '../../components/Header/Header';
import InstructionTitle from '../../components/InstructionTitle/InstructionTitle';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import TipWithGoodBadExample from "../../components/TipWithGoodBadExample/TipWithGoodBadExample";
import { useNavigate, useParams } from 'react-router-dom';
import Footer from "../../components/Footer/Footer";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const images = [
  {
    label: 'Close all the doors in the room, and assure the room is well lit.',
    imgPath: process.env.PUBLIC_URL + './videos/room-tip-1.mp4',
    goodImg: process.env.PUBLIC_URL + './tips/tip-1-1.png',
    badImg: process.env.PUBLIC_URL + './tips/tip-1-2.png',
  },
  {
    label: 'Choose a wall to be the first photo in the series. Include the floor, ceiling, and four corners of the wall.',
    imgPath: process.env.PUBLIC_URL + './videos/room-tip-2.mp4',
    goodImg: process.env.PUBLIC_URL + './tips/tip-2-1.png',
    badImg: process.env.PUBLIC_URL + './tips/tip-2-2.png',
  },
  {
    label: 'Take photos of the remaining walls in a clockwise direction (from left to right).',
    imgPath: process.env.PUBLIC_URL + './videos/room-tip-3.mp4',
    goodImg: process.env.PUBLIC_URL + './tips/tip-3-1.png',
    badImg: process.env.PUBLIC_URL + './tips/tip-3-2.png',
  }
];

function VideoTipsPage() {
  const navigate = useNavigate();
  const [swipeRef, setSwipeRef] = React.useState<ISwiper>()
  const params = useParams()
  const [activeStep, setActiveStep] = React.useState(0);
  const [display, setDisplay] = React.useState(false);


  const handleNext = () => {
    if (activeStep === 2) {
      if (params.returnId)
        navigate(`/${params.claimId}/${params.returnId}/${params.roomId}/camera-tips`)
      else
        navigate(`/${params.claimId}/${params.roomId}/camera-tips`)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    swipeRef?.slideNext()

  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    swipeRef?.slidePrev()
  }

  const showDialog = () => {
    setDisplay(true)
  };



  return (
    <>
      <Header />
      <div className={'w-full flex-1 flex flex-col items-center pb-48'}>
        <div className={'w-full desktops:w-desktop px-2 desktops:px-0 max-w-desktop flex flex-col items-center flex-1'}>
          <InstructionTitle className='text-left w-full' text='Tips for better capturing' />
          <Swiper onInit={(s) => setSwipeRef(s)} onSlideChange={(s) => setActiveStep(s.activeIndex)} pagination={true} modules={[Pagination]} className="mySwiper">
            {images.map((step, index) => (
              <SwiperSlide key={index}>
                <div >
                  <video autoPlay loop muted playsInline>
                    <source src={step.imgPath} type='video/mp4' />
                  </video>
                  <InstructionTitle className='text-left' subtitleText={step.label} text={`Tip ${index + 1}/3`} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <TipsDialog open={display} tip={activeStep} handleClose={() => setDisplay(false)} />
      </div>
      <Footer>
        <button className='underline text-gray-500 text-sm font-semibold py-2 px-4' onClick={showDialog}>Please Clarify</button>
        <div className='flex flex-row w-full gap-2 px-2 flex-1 max-w-desktop items-center desktops:w-desktop'>
          <button disabled={activeStep === 0} onClick={handlePrev} className='rounded bg-blue-100 text-blue-600 hover:opacity-75 font-bold basis-1/2 h-12'>Previous</button>
          <button onClick={handleNext} className='rounded bg-green-500 text-white hover:opacity-75 font-bold basis-1/2 h-12'>Next</button>
        </div>
      </Footer>
    </>
  );
}

function TipsDialog({ open, handleClose, tip = 0 }: any) {

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <div style={{ maxWidth: '768px', height: '100%', margin: '0 auto' }}>
        <div className={'w-full flex-1 flex flex-col items-center pb-20'}>
          <div className={'w-full desktops:w-desktop px-2 desktops:px-0 max-w-desktop flex flex-col items-center flex-1'}>
            <InstructionTitle subtitleText='' text='Tips for better capturing' />
            <TipWithGoodBadExample goodImageSrc={`${images[tip].goodImg}`} tipNumber={tip + 1}
              badImageSrc={`${images[tip].badImg}`} tipText={images[tip].label} />

          </div>


        </div>

        <div className='flex fixed pt-10 bg-white bottom-0 flex-row w-full gap-2 px-2 flex-1 max-w-desktop items-center desktops:w-desktop'>
          <button onClick={handleClose} className='rounded bg-green-500 text-white hover:opacity-75 font-bold w-screen h-12 left-0'>Got it</button>
        </div>

      </div>
    </Dialog>
  );
}


export default VideoTipsPage;
